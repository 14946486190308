import { MODAL_TYPES } from '../common/AppHeader/data';

/* eslint-disable max-len,vue/max-len */
export const faqAttestatciiPage = [
  {
    question: 'что&nbsp;означает «прикрепиться к&nbsp;школе»?',
    answer: `Прикрепиться к школе — это значит стать ее учеником. Обычно это делается при: поступлении в школу, переводе из другого учебного заведения или для сдачи аттестаций и экзаменов ученикам на семейной форме. Для прикрепления к нашей школе необходимо подать заявление, <button class="m-btn" data-modal="${MODAL_TYPES.attachment}">оставьте заявку</button> и специалист отдела заботы ответит на ваши вопросы`,
  },
  {
    question: 'как проходит прикрепление?',
    answer: `Для прикрепления к нашей онлайн-школе вам необходимо <button class="m-btn" data-modal="${MODAL_TYPES.attachment}">оставьте заявку</button> на сайте. Все остальное мы сделаем за вас. Просто как раз-два-три`,
  },
  {
    question: 'какие документы нужны для прикрепления?',
    answer: 'Вам понадобятся следующие документы: паспорт, аттестат об основном общем образовании (для 9 классов), аттестат о среднем общем образовании (для 11 классов)',
  },
  {
    question: 'можно ли прикрепиться к онлайн-школе, если я живу в другом регионе?',
    answer: 'Да, вы можете прикрепиться к нашей школе из любой точки мира полностью онлайн',
  },
  {
    question: 'могу ли я сдавать аттестации онлайн?',
    answer: 'Да, аттестации проходят в установленные даты всегда онлайн на нашей платформе. Нужно будет сдать тесты и проверочные работы на платформе. Ученики сдают аттестацию без камер, в комфортной домашней атмосфере',
  },
  {
    question: 'сколько у меня есть времени на сдачу аттестации?',
    answer: `Аттестация открыта на платформе около 2 недель в период проведения, на выполнение работы по одному предмету дается 40 минут. <button class="m-btn" data-modal="${MODAL_TYPES.attachment}">Получите бесплатный доступ</button> к платформе на неделю`,
  },
  {
    question: 'есть ли возможность пересдачи аттестации?',
    answer: 'В случае неудовлетворительного результата аттестации у вас будет возможность ее пересдать в указанные периоды',
  },
];

export const faqSchoolVneurClassPage = [
  {
    question: 'как&nbsp;проходят занятия на&nbsp;внеурочке?',
    answer: 'Занятия во&nbsp;внеурочное время проходят в&nbsp;более свободной атмосфере, чем&nbsp;обычные уроки. На&nbsp;них&nbsp;не&nbsp;ставят оценки и&nbsp;посещать их&nbsp;может любой желающий',
  },
  {
    question: 'как&nbsp;часто проходят занятия?',
    answer: 'Вы&nbsp;можете посещать внеурочные занятия каждый день, а&nbsp;можете выбрать те&nbsp;курсы, которые вас&nbsp;интересуют, и&nbsp;приходить только на&nbsp;них. Все&nbsp;зависит от&nbsp;вашего желания и&nbsp;предпочтений',
  },
  {
    question: 'можно&nbsp;ли&nbsp;совмещать внеурочные курсы со&nbsp;школой?',
    answer: 'Конечно, внеурочка проводит после&nbsp;уроков с&nbsp;15:00. Если&nbsp;у&nbsp;вас&nbsp;не&nbsp;получается прийти на&nbsp;занятие, вы&nbsp;всегда можете посмотреть его&nbsp;в&nbsp;записи на&nbsp;платформе',
  },
  {
    question: 'какие курсы я&nbsp;могу посещать на&nbsp;внеурочке?',
    answer: `
              <p>
                Наши курсы открыты для&nbsp;всех школьников, чтобы&nbsp;каждый мог&nbsp;найти занятие по&nbsp;душе. В&nbsp;рамках внеурочных занятий вы&nbsp;освоите основы программирования на&nbsp;JavaScript и&nbsp;Python, узнаете, как&nbsp;правильно распоряжаться своими финансами, сможете посещать литературные кружки и&nbsp;развивать soft skills. Вы можете <button class="m-btn" data-modal="${MODAL_TYPES.uchPlan}">посмотреть</button> расписание внеурочных занятий для&nbsp;всех классов
              </p>
              <p>
              Кроме того, на&nbsp;наших курсах мы&nbsp;готовим выпускников к&nbsp;ОГЭ&nbsp;и&nbsp;ЕГЭ&nbsp;по&nbsp;профильным направлениям: <a href="/online-school/fiziko-matematicheskij">физмат</a>, <a href="/online-school/gumanitarnyj">гуманитарное</a>, <a href="/online-school/lingvisticheskij">лингвистическое</a> и <a href="/online-school/socialno-ekonomicheskij">соц.экономическое</a>
              </p>
            `,
  },
  {
    question: 'как&nbsp;попасть на&nbsp;внеурочку?',
    answer: `
              <p>
                Чтобы&nbsp;посещать занятия, вам&nbsp;не&nbsp;нужно собирать документы. Достаточно <button class="m-btn" data-modal="${MODAL_TYPES.demo30}">зарегистрироваться на&nbsp;платформе</button> школы. После&nbsp;этого вам&nbsp;станут доступны все&nbsp;внеклассные курсы. Перейти на&nbsp;платформу вы&nbsp;можете здесь
              </p>
            `,
  },
];

export const faqSchoolClassPage = [
  {
    question: 'какие документы нужны для&nbsp;поступления?',
    answer: 'базовый пакет документов включает: паспорт родителя и&nbsp;ребенка, свидетельство о&nbsp;рождении ребенка, СНИЛС, личная карта обучающегося и&nbsp;аттестат об&nbsp;окончании 9-го класса (для&nbsp;поступающих в&nbsp;10–11&nbsp;класс). Оставьте заявку, и&nbsp;мы&nbsp;подробно расскажем, какие документы потребуются и&nbsp;как&nbsp;их собрать.',
  },
  {
    question: 'как&nbsp;перевести ребенка на&nbsp;семейное обучение?',
    answer: `сперва предупредите директора вашей школы о&nbsp;переходе. Затем напишите уведомление и&nbsp;оповестите Департамент образования вашего города или&nbsp;округа о&nbsp;вашем решении. После&nbsp;определитесь со&nbsp;школой, куда вы&nbsp;прикрепитесь, чтобы&nbsp;проходить аттестации и&nbsp;экзамены. Прикрепиться можно к&nbsp;онлайн-школе «Синергия», мы&nbsp;с&nbsp;радостью вам&nbsp;в&nbsp;этом поможем.
            <br><br>
            Также&nbsp;в&nbsp;нашей школе можно учиться заочно, в&nbsp;этом случае вам&nbsp;не&nbsp;нужно будет переводиться на&nbsp;семейное обучение, поступить на&nbsp;заочную форму также&nbsp;просто, как&nbsp;сменить школу, а&nbsp;мы&nbsp;позаботимся, чтобы&nbsp;поступление прошло быстро и&nbsp;комфортно.`,
  },
  {
    question: 'какие различия между&nbsp;заочной и&nbsp;семейной формой обучения?',
    answer: `
           заочная форма подразумевает, что&nbsp;ребенок зачислен в&nbsp;нашу школу с&nbsp;первого дня&nbsp;обучения, переход к&nbsp;нам&nbsp;на&nbsp;«заочку» юридически эквивалентен переходу ребенка в&nbsp;другую классическую школу. В&nbsp;9&nbsp;и&nbsp;11&nbsp;классах ученикам заочной форме необходимо будет приехать на&nbsp;ОГЭ&nbsp;или&nbsp;ЕГЭ&nbsp;в&nbsp;Москву. Наши ученики-заочники получают московский аттестат&nbsp;гос. образца Университета Синергия.
           <br><br>
           Семейная форма обучения подразумевает, что&nbsp;ребенок переведен на&nbsp;семейное обучение и&nbsp;за&nbsp;его обучение отвечает семья. Чтобы&nbsp;не&nbsp;обучать ребенка ежедневно самостоятельно, родители могут перевести ребенка на&nbsp;семейную форму обучения, но&nbsp;доверить его&nbsp;обучение лицензированной удобной онлайн-школе с&nbsp;сильными преподавателями и&nbsp;проверенными материалами по&nbsp;ФГОС.
           <br><br>
            По&nbsp;закону родители могут сами обучать ребенка, но&nbsp;не&nbsp;могут его&nbsp;аттестовать, поэтому на&nbsp;время прохождения аттестации ребенку, который находится на&nbsp;семейном обучении, необходимо прикрепиться к&nbsp;школе: прикрепиться можно к&nbsp;нашей онлайн-школе или&nbsp;к&nbsp;любой другой школе, например, по&nbsp;месту жительства. Выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены.
            <br><br>
            На&nbsp;любой форме обучения в&nbsp;онлайн-школе вы&nbsp;можете посещать занятия, вам&nbsp;открыт доступ ко&nbsp;материалам на&nbsp;платформе, вам&nbsp;также&nbsp;будет доступен весь сервис согласно выбранному тарифу (Премиум, Комфорт или&nbsp;Лайт).`,
  },
  {
    question: 'как проходит обучение?',
    answer: `
            в&nbsp;зависимости от&nbsp;выбранного тарифа обучения, ребенок может учится в&nbsp;мини-группах (связь с&nbsp;учителем через&nbsp;камеру и&nbsp;микрофон, максимальное внимание вашему ребенку) или&nbsp;на&nbsp;вебинрах (связь с&nbsp;учителем через&nbsp;чат), смотреть уроки можно в&nbsp;записи, во&nbsp;время и&nbsp;после урока ученику доступна интерактивная платформа с&nbsp;тренажерами, конспектами и&nbsp;домашними заданиями.
            <br><br>
            С&nbsp;каждым учеником работает целая команда заботы: классный руководитель поможет адаптироваться в&nbsp;школе и&nbsp;классе, с&nbsp;радостью решит любые организационные вопросы; наставник подскажет по&nbsp;предметным вопросам и&nbsp;поможет с&nbsp;домашками; также&nbsp;в&nbsp;нашей школе работают тьютор, психолог и&nbsp;профориентолог.`,
  },
  {
    question: 'как проходят экзамены в&nbsp;онлайн‑школе?',
    answer: `
              полностью онлайн для&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классов:
              <ol>
                <li>для&nbsp;учеников (на&nbsp;любой форме обучения) 5–8&nbsp;и&nbsp;10&nbsp;классов аттестация проходит онлайн. Аттестация в&nbsp;5–8&nbsp;и&nbsp;10&nbsp;классах&nbsp;— это&nbsp;годовые контрольные работы по&nbsp;всем школьным предметам, которые проходят в&nbsp;онлайн-формате с&nbsp;включёнными камерами и&nbsp;микрофонами под&nbsp;контролем учителя.</li>
              </ol>
              <p>Очно для&nbsp;9&nbsp;и&nbsp;11&nbsp;классов:</p>
              <ol>
                <li>Для&nbsp;выпускных 9&nbsp;и&nbsp;11&nbsp;классов аттестация проходит в&nbsp;виде государственных экзаменов (ОГЭ&nbsp;и&nbsp;ЕГЭ). Ученикам нашей школы, которые обучаются на&nbsp;заочной форме, необходимо будет приехать на&nbsp;экзамены в&nbsp;Москву. Выпускники заочной формы в&nbsp;нашей школе получат московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия.</li>
                <li>Те, кто&nbsp;обучается у&nbsp;нас&nbsp;на&nbsp;семейной форме с&nbsp;прикреплением, могут выбрать: сдавать экзамены от&nbsp;нашей школы или&nbsp;от&nbsp;школы по&nbsp;выбору (часто это&nbsp;школа по&nbsp;месту жительства), куда нужно будет прикрепиться на&nbsp;период экзаменов. Важно, что&nbsp;при&nbsp;этом выпускник получит аттестат именно той&nbsp;школы, в&nbsp;которой сдавал экзамены. Если&nbsp;выпускник сдаёт экзамены в&nbsp;Москве от&nbsp;нашей школы, то&nbsp;получит московский аттестат&nbsp;гос. образца от&nbsp;Университета Синергия, если&nbsp;в&nbsp;другой школе, то&nbsp;аттестат той&nbsp;школы.</li>
              </ol>`,
  },
];

export default {
  faqAttestatciiPage,
  faqSchoolVneurClassPage,
  faqSchoolClassPage,
};
