var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"su-faq"},[_c('div',{staticClass:"su-faq__info"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('a',{attrs:{"href":_vm.$getTelegramLink(_vm.$route),"target":"_blank"}},[_c('span',[_vm._v("задать вопрос")]),_vm._v(" "),_c('img',{attrs:{"src":"/v2/school-university/faq/tg.svg","alt":"telegram","loading":"lazy"}})])]),_vm._v(" "),_c('div',{staticClass:"su-faq__questions"},_vm._l((_vm.questions),function(item,index){return _c('div',{key:index,class:['su-faq__question', {
        'su-faq__question--active': _vm.openedQuestions.includes(index),
      }]},[_c('button',{staticClass:"m-btn su-faq__question_btn",on:{"click":function($event){return _vm.toggleOpen(index)}}},[_c('su-faq-arrow'),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(item.question)}})],1),_vm._v(" "),_c('div',{ref:`answer-${index}`,refInFor:true,class:['su-faq__question_answer', {
          'vneur': _vm.schoolVneurClassPage,
        }],style:({
          height: _vm.openedQuestions.includes(index) ? _vm.getHeight(index) : 0,
        }),domProps:{"innerHTML":_vm._s(item.answer)}})])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v("собрали для вас ответы на частые "),_c('br'),_vm._v("и волнующие вопросы")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("\n      не нашли ответа на ваш вопрос?\n      "),_c('span',[_vm._v("задайте его специалисту из отдела заботы")])])
}]

export { render, staticRenderFns }